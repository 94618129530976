import React from 'react';

import styled from 'styled-components';

import { Section } from '@layouts';
import { device } from '@styles';
import type { FeaturesSectionProps } from 'src/types';

import SectionTitle from '../SectionTitle';
import Feature from './Feature';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;

    @media ${device.laptop} {
        grid-template-columns: 1fr;
    }
`;

const Features: React.FC<FeaturesSectionProps> = ({ content }) => {
    const { title, subtitle, headers } = content;

    return (
        <Section id="Features">
            <SectionTitle title={title} desc={subtitle} />
            <Grid>
                {headers.map((header, index) => (
                    <Feature key={index} index={index} content={content} />
                ))}
            </Grid>
        </Section>
    );
};

export default Features;
