import React from 'react';

import styled from 'styled-components';

import { Icon, Paragraph } from '@components';
import { device } from '@styles';
import type { ContentFeatures } from 'src/types';

const StyledFeature = styled.div`
    padding: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IconWrapper = styled.div`
    padding: 100px 0px;
    svg {
        min-height: 90px;
    }

    @media ${device.laptop} {
        padding: 60px 0px;
    }
`;

export type FeatureProps = {
    content: ContentFeatures;
    index: number;
};

const Feature: React.FC<FeatureProps> = ({ content, index }) => {
    const { icons, headers, paragraphs } = content;

    return (
        <StyledFeature>
            <Container>
                <IconWrapper>
                    <Icon name={icons[index]} />
                </IconWrapper>
                <Paragraph
                    data={{
                        header: headers[index],
                        text: paragraphs[index],
                    }}
                />
            </Container>
        </StyledFeature>
    );
};

export default Feature;
