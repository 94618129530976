import React from 'react';

import styled from 'styled-components';

import { Section } from '@layouts';
import { device } from '@styles';
import type { NextProps } from 'src/types';

const Link = styled.a`
    color: var(--black) !important;

    p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 900;
        opacity: 0.6;
        color: var(--black);

        @media ${device.tablet} {
            font-size: 14px;
            line-height: 22px;
        }
    }

    h3 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 900;

        @media ${device.tablet} {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &:hover {
        text-decoration: underline;
    }
`;

const Next: React.FC<NextProps> = ({ content }) => {
    if (!content) return null;
    const { name, quickDesc } = content;
    const nameWithoutSpace = name.replace(/\s/g, '');
    const description = quickDesc.toLowerCase();

    return (
        <Section bgcolor="var(--white)">
            <Link
                href={`/projects/${nameWithoutSpace}`}
                arial-label={`Go to ${nameWithoutSpace}`}
            >
                <p>Next Project</p>
                <h3>{`${name}: ${description} →`}</h3>
            </Link>
        </Section>
    );
};

export default Next;
