import React, { useMemo } from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';

import { useLastActiveEntry } from '@hooks';
import { device } from '@styles';
import { SectionsNavProps } from 'src/types';

const Wrapper = styled.div`
    position: sticky;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    @media ${device.tablet} {
        padding: 0px 15px;
    }
`;

const Nav = styled.nav`
    display: inline-block;
    background-color: #161a1d;
    border-radius: 50px;
    padding: 4px;
    border: 1px solid #333943;
    box-shadow: 0px 6px 20px hsl(0, 0%, 5%);

    @media ${device.tablet} {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const Container = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #adadad;

    .active {
        color: var(--white);
    }

    @media ${device.tablet} {
        overflow: auto;
        justify-content: flex-start;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const ListItem = styled.li`
    a {
        display: inline-block;
        font-size: var(--fs-xs);
        line-height: 22px;
        font-weight: 500;
        padding: 12px 25px;

        @media ${device.tablet} {
            white-space: nowrap;
            padding: 6px 14px;
        }
    }
`;

const options = { rootMargin: '-47% 0% -47% 0%' };

const SectionsNav: React.FC<SectionsNavProps> = ({ content }) => {
    const [sections, name] = content;
    const active = useLastActiveEntry(sections, options);

    const nameWithoutSpace = name?.replace(/\s/g, '');

    const mapSections = useMemo(
        () =>
            sections.map((item, index) => {
                const { id } = item;
                return (
                    <ListItem key={index}>
                        <Link
                            to={`#${id}`}
                            className={`${active === id ? 'active' : ''}`}
                            aria-label={`Move to ${id} section of ${nameWithoutSpace}`}
                        >
                            {item.id}
                        </Link>
                    </ListItem>
                );
            }),
        [sections, active]
    );

    return (
        <Wrapper>
            <Nav>
                <Container>
                    {mapSections}
                    <ListItem>
                        <a
                            href={`/projects/${nameWithoutSpace}`}
                            aria-label={`Move to ${nameWithoutSpace} project`}
                        >
                            Next project
                        </a>
                    </ListItem>
                </Container>
            </Nav>
        </Wrapper>
    );
};

export default SectionsNav;
