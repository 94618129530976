import React, { useMemo } from 'react';

import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { Icon, Iframe, Paragraph } from '@components';
import { Section } from '@layouts';
import { device } from '@styles';
import type { GoalsSectionProps } from 'src/types';

import SectionTitle from './SectionTitle';

const Grid = styled.ul`
    display: grid;

    @media ${device.tablet} {
        grid-template-rows: 1fr;
    }
`;

const Container = styled.li<{ vertical: boolean }>`
    display: flex;
    justify-content: center;
    padding: 100px 0;
    gap: 50px;

    ${props =>
        props.vertical &&
        css`
            align-items: flex-start;
            flex-direction: column;
        `};

    ${props =>
        !props.vertical &&
        css`
            max-height: 800px;
            align-items: center;

            @media ${device.laptop} {
                flex-direction: column;
                align-items: flex-start;
            }
        `};

    &:nth-child(even) > div:nth-child(1) {
        order: 2;

        @media ${device.laptop} {
            order: initial;
        }
    }

    & > div:nth-child(1) {
        max-width: 550px;

        @media ${device.laptopL} {
            max-width: 450px;
        }

        @media ${device.laptop} {
            max-width: 550px;
        }
    }

    @media ${device.laptop} {
        padding: 75px 0;
        max-height: initial;
    }

    @media ${device.tablet} {
        padding: 50px 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img,
    svg {
        height: auto;
        max-width: 100%;
    }
`;

const Goals: React.FC<GoalsSectionProps> = ({ content }) => {
    const { name, title, desc, headers, paragraphs, images, iframes, alt } =
        content;
    const sectionName = useMemo(
        () => name.charAt(0).toUpperCase() + name.slice(1, name.length),
        []
    );
    let image: IGatsbyImageData | undefined;

    return (
        <Section id={sectionName}>
            <SectionTitle title={title} desc={desc} />
            <Grid>
                {headers.map((header, index) => {
                    if (images) {
                        image = getImage(images[index]);
                    }
                    return (
                        <Container
                            key={index}
                            vertical={name === 'goals' && index === 0}
                        >
                            <Paragraph
                                data={{
                                    header,
                                    text: paragraphs[index],
                                }}
                            />
                            {alt && alt[index] && <Icon name={alt[index]} />}
                            {images && images[index] && (
                                <ImageWrapper>
                                    {image && (
                                        <GatsbyImage
                                            image={image}
                                            alt={header + title}
                                        />
                                    )}
                                </ImageWrapper>
                            )}
                            {iframes && iframes[index] && (
                                <Iframe id={iframes[index]} />
                            )}
                        </Container>
                    );
                })}
            </Grid>
        </Section>
    );
};

export default Goals;
