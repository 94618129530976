import React from 'react';

import styled, { css } from 'styled-components';

import { Paragraph, SectionTitle } from '@components';
import Tabs from '@components/Tabs';
import { Section } from '@layouts';
import { device } from '@styles';
import { TechstackSectionProps } from 'src/types';

const Grid = styled.div<{ length: number }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 150px;

    & > :last-of-type {
        ${({ length }) =>
            length % 2 !== 0 &&
            css`
                grid-column: span 2;
                max-width: 565px;
                justify-self: center;

                @media ${device.tablet} {
                    grid-column: unset;
                    justify-self: unset;
                }
            `}
    }

    @media ${device.laptop} {
        gap: 100px;
    }

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        gap: 75px;
    }
`;

const Techstack: React.FC<TechstackSectionProps> = ({ content }) => (
    <Section id="Tech">
        <Tabs {...content} />
        {/* <SectionTitle title={title} desc={desc} />
            <Grid length={headers.length}>
                {headers.map((header, index) => (
                    <Paragraph
                        key={index}
                        data={{
                            header,
                            text: paragraphs[index],
                        }}
                    />
                ))}
            </Grid> */}
    </Section>
);

export default Techstack;
