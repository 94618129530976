import React from 'react';

import styled from 'styled-components';

import { List, Paragraph, SectionTitle } from '@components';
import { Section } from '@layouts';
import { device } from '@styles';
import type { ProjectSectionProps } from 'src/types';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 0.5fr);
    colum-gap: auto;
    grid-auto-flow: row;

    & > {
        :nth-child(1) {
            grid-column-start: 2;
            grid-row: 1 / span 2;
        }

        :nth-child(2) {
            grid-column-start: 2;
            grid-row: 3 / span 2;
        }

        :nth-child(3) {
            grid-column-start: 2;
            grid-row: 5 / span 2;
        }

        :nth-child(4) {
            grid-column-start: 1;
            grid-row: 1 / span 3;
        }

        :nth-child(5) {
            grid-column-start: 1;
            grid-row: 5 / span 2;
        }
    }

    & > div {
        @media ${device.tablet} {
            grid-column-start: unset !important;
            grid-row: unset !important;
        }
    }

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 24px;
    }
`;

const Overview: React.FC<ProjectSectionProps> = ({ content }) => {
    const { tech, sections } = content;
    if (!sections.overview) return <></>;
    const { paragraphs, headers, featuresList } = sections.overview;

    return (
        <Section id="Overview">
            <SectionTitle
                title="Overview"
                desc="A quick look at general information about the project with a brief description of the development process."
            />
            <Grid>
                {paragraphs &&
                    paragraphs.map((item, index) => (
                        <Paragraph
                            key={index}
                            data={{
                                header: headers[index],
                                text: paragraphs[index],
                            }}
                        />
                    ))}
                <List
                    large
                    single
                    title="Notable features"
                    list={featuresList}
                />
                <List large single widgets title="Tech stack" list={tech} />
            </Grid>
        </Section>
    );
};

export default Overview;
