import React, { FC, useState } from 'react';

import styled from 'styled-components';

import { TechstackSectionProps } from 'src/types';

import Icon from './Icon';

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto;
    gap: 32px;
    justify-content: start;
    padding: 60px 40px;
    border-radius: 24px;

    background: var(--bg-gray);
`;

const TopPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    h2 {
        font-size: 48px;
        line-height: normal;
        margin-top: -10px;
    }
`;

const BottomPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const Paragraph = styled.p<{ active: boolean }>`
    display: ${({ active }) => (active ? 'block' : 'none')};
    padding: 10px 0px;
`;

const ListItem = styled.li<{ active: boolean }>`
    display: flex;
    align-items: center;
    gap: 20px;
    width: fit-content;
    cursor: pointer;

    padding: 12px 0px;
`;

const Header = styled.span<{ active: boolean }>`
    color: ${({ active }) => (active ? 'var(--white)' : 'var(--gray)')};

    ${ListItem}:hover & {
        color: var(--white);
    }
`;

const Indicator = styled.span<{ active: boolean }>`
    width: ${({ active }) => (active ? '64px' : '32px')};
    height: 1px;
    background-color: ${({ active }) =>
        active ? 'var(--white)' : 'var(--gray)'};
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 150ms;

    ${ListItem}:hover & {
        width: 64px;
        background-color: var(--white);
    }
`;

const Tabs: FC<TechstackSectionProps['content']> = ({
    title,
    paragraphs,
    icons,
    headers,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index: number) => setActiveIndex(index);
    return (
        <Wrapper>
            <TopPanel>
                <h2>{title}</h2>
                <Icon name={icons[activeIndex]} />
            </TopPanel>
            <BottomPanel>
                <nav>
                    {headers.map((header, index) => {
                        const isActive = activeIndex === index;
                        return (
                            <ListItem
                                key={index}
                                active={isActive}
                                onClick={() => handleClick(index)}
                            >
                                <Indicator active={isActive} />
                                <Header active={isActive}>{header}</Header>
                            </ListItem>
                        );
                    })}
                </nav>
                {paragraphs.map((paragraph, index) => {
                    const isActive = activeIndex === index;
                    return (
                        <Paragraph key={index} active={isActive}>
                            {paragraphs[index]}
                        </Paragraph>
                    );
                })}
            </BottomPanel>
        </Wrapper>
    );
};

export default Tabs;
