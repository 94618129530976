import React, { useMemo } from 'react';

import { useCheckActiveSections } from '@hooks';
import type { MainProps } from 'src/types';

import Features from './Features';
import Goals from './Goals';
import Overview from './Overview';
import SectionsNav from './SectionNav';
import Techstack from './TechStack';

const Main: React.FC<MainProps> = ({ content }) => {
    const { project, next } = content;
    const { goals, story, tech, features } = project.sections;
    const sectionsActive = useCheckActiveSections(project);
    const sectionsLength = useMemo(
        () => Object.keys(project.sections).length > 1,
        [project]
    );

    return (
        <main>
            {sectionsLength && next && (
                <SectionsNav content={[sectionsActive, next.name]} />
            )}
            <Overview content={project} />
            {goals && <Goals content={goals} />}
            {story && <Goals content={story} />}
            {tech && <Techstack content={tech} />}
            {features && <Features content={features} />}
        </main>
    );
};

export default Main;
