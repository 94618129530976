import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Button, Icon, Iframe, Paragraph, Widgets } from '@components';
import { device } from '@styles';
import { HeroProps } from 'src/types';

const Wrapper = styled.section`
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.laptop} {
        height: auto;
    }
`;

const Content = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
    align-items: center;
    max-width: 1000px;
    height: initial;
    background-color: #161a1d;
    border-radius: 16px;
    padding: 30px;

    .img,
    & > svg {
        background-size: cover;
        border-radius: 5px;
        width: 100%;
        height: auto;
        object-fit: cover;

        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

        @media ${device.laptop} {
            grid-column: 1 / -1;
            opacity: 0.25;
            height: 100%;
        }
    }

    & > svg {
        padding: 100px;
        border: 1px solid var(--gray);
    }

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        margin-top: 60px;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 350px;

    @media ${device.tablet} {
        margin-bottom: 20px;
    }

    & > div:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

const Tag = styled.div`
    display: inline-block;
    width: fit-content;
    height: 24px;
    background-color: var(--orange);
    margin-bottom: 24px;
    padding: 4px 20px;
    border-radius: 4px;
`;

const ButtonsContainer = styled.div`
    display: flex;
`;

const Hero: React.FC<HeroProps> = ({ content }) => {
    const {
        tech,
        links,
        linkIcons,
        name,
        iframe,
        description,
        quickDesc,
        img,
    } = content;
    const image = getImage(img);

    return (
        <Wrapper id="hero">
            <Content>
                <Info>
                    {quickDesc && <Tag>{quickDesc}</Tag>}
                    <Paragraph data={{ header: name, text: description }} />
                    <ButtonsContainer>
                        {links &&
                            links.map((item, i) => (
                                <Button
                                    key={i}
                                    square
                                    icon={linkIcons && linkIcons[i]}
                                    primary={
                                        linkIcons && linkIcons[i] === 'external'
                                    }
                                    href={item}
                                >
                                    {linkIcons && linkIcons[i] === 'github'
                                        ? 'Open in github'
                                        : 'Live server'}
                                </Button>
                            ))}
                    </ButtonsContainer>
                </Info>
                {image && !iframe && <GatsbyImage image={image} alt="" />}
                {iframe && <Iframe id={iframe} autoplay />}
                {!image && !iframe && <Icon name="logo" />}
            </Content>
            <Widgets list={tech} />
        </Wrapper>
    );
};

export default Hero;
