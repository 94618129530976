import React, { useMemo } from 'react';

import { graphql } from 'gatsby';

import { Hero, Main, Next } from '@components/sections/Project';
import { useFetchNextProject } from '@hooks';
import { Page } from '@layouts';
import type { ProjectProps } from 'src/types';

const Project: React.FC<ProjectProps> = ({ data }) => {
    const projectDetails = data.project.frontmatter;
    const sections = useMemo(
        () => data.sections.nodes.map(node => node.frontmatter),
        []
    );

    const project = useMemo(() => {
        let temp = {};

        sections.forEach(section => {
            temp = {
                ...temp,
                [section.name]: section,
            };
        });

        const projectInfo = {
            ...projectDetails,
            sections: temp,
        };
        return projectInfo;
    }, []);

    const next = useFetchNextProject(project.name, project.featured);

    return (
        <Page>
            <Hero content={project} />
            <Main content={{ project, next }} />
            <Next content={next} />
        </Page>
    );
};

export const query = graphql`
    query ProjectData($slug: String, $sectionsDir: String) {
        project: mdx(frontmatter: { slug: { eq: $slug } }) {
            frontmatter {
                name: slug
                icon
                img {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                iframe
                quickDesc
                tech
                links
                linkIcons
                featured
                description
            }
        }
        sections: allMdx(
            filter: {
                internal: { contentFilePath: { regex: $sectionsDir } }
                frontmatter: { name: { ne: $slug } }
            }
        ) {
            nodes {
                frontmatter {
                    name
                    title
                    icons
                    headers
                    desc
                    paragraphs
                    iframes
                    alt
                    featuresList
                    images {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default Project;
